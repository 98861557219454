@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-icons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css";

@font-face {
  font-family: "FontLight";
  src: url("./fonts/Akkurat\ Light.ttf") format("truetype");
}
@font-face {
  font-family: "FontDark";
  src: url("./fonts/Akkurat\ Bold.ttf") format("truetype");
}
$font-mainLight: "FontLight", sans-serif;
$font-mainDark: "FontDark", sans-serif;
body {
  font-family: $font-mainLight;
}
::-webkit-scrollbar {
  width: 5px;

}
::-webkit-scrollbar-track {
  background: #f1f1f1;
 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #000; 
}
.admin-header-img img {
  width: 100% !important;
  height: 20vh !important;
  overflow-x: hidden;
  margin-top: 10px;
  margin-bottom: 100px;
  object-fit: contain;
}

input[type="checkbox"] {
  width: 50px !important;
  height: 50 !important;
  padding: 10px;
}
.spinner {
  width: 150px !important;
  height: 150px !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.nmt-10{
  margin-top: -10px;
}
.nmb-10{
  margin-bottom: -10px;
}
.spinnerDiv {
  width: 100vw;
  height: 100vh !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  position: fixed;
  background-color: rgba(119, 128, 138, 0.2);
  backdrop-filter: blur(1px);
}

.order-table input.comment,
.order-table input.reviewers,
.order-table input.status {
  width: 50px !important;
}

.border-custom {
  border: 1px solid #333;
  padding: 3rem;
  border-radius: 5px;
  box-shadow: 2px 2px 10px #333, -2px -2px 10px #333;
}

.sort-table-btn {
  background-color: transparent;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  outline: none;
}

.sel-status {
  font-size: 12px;
  text-align: left;
  width: 120px;
}
.sel-unit {
  font-size: 12px;
  text-align: left;
  width: 80px;
}
.btn-small {
  width: 70px;
  text-align: center;
  outline: none;
}

.wtow {
  width: 350px;
}

.wone {
  width: 720px;
}
.pagenotfound {
  color: red;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  min-height: 75vh;
  transform: translate(0, 10%);
}
.pagenotfound h3 {
  font-size: 3rem;
  font-weight: bold;
}
.pagenotfound h1 {
  font-weight: bold;
  font-size: 5rem;
}
.pagenotfound p {
  font-weight: bold;
}
.pagenotfound .btn {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="number"].product-quan::-webkit-inner-spin-button {
  -webkit-appearance: auto;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.ml-negative {
  margin-left: -18px;
}
.hanan {
  margin: 1.1rem !important;
}

.textarae-error {
  border: 1px solid red;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #333;
  border-color: #333;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #333;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #333;
  border-color: #333;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #333;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}
.table-show-limit,
.sku-table-limit-search,
.user-table-show-limit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.show-limit,
.search-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

@media (max-width: 960px) {
  .order-table tr th {
    font-size: 10px !important;
  }

  .order-table tr td {
    font-size: 10px !important;
  }
}

.table-image {
  width: 50px !important;
  height: 50px !important;
  display: block;
}
@media (max-width: 766px) {
  .order-table tr th {
    font-size: 10px !important;
  }

  .order-table tr td {
    font-size: 13px !important;
  }

  .order-table input.comment,
  .order-table input.reviewers,
  .order-table input.status {
    width: 40px !important;
  }
}

@media (max-width: 1000px) {
  .wtow {
    width: 200px;
  }

  .wone {
    width: 400px;
  }
}

@media (max-width: 770px) {
  .wtow {
    width: 100%;
  }

  .wone {
    width: 100%;
  }
}

@media (max-width: 470px) {
  .sku-table-limit-search {
    display: block;
  }
}
// sass


$footer-background-color:rgb(51, 51, 51);
$table-header-background-color:rgb(51, 51, 51);
$table-header-color:#fff;
$footer-text-clor: rgb(255, 255, 255);
$label-text-color: #fff;
$label-background-color-stroke: rgb(255, 255, 255);
$label-background-color-fill: rgba(0, 0, 0, 0.7);
$label-radius: 5px;
$button-background-color-stroke: rgb(255, 255, 255);
$button-background-color-fill: rgba(0, 0, 0, 0.8);
$button-radius: 5px;
$button-shadow: 0px 5px 10px black;
$card-header-background-color: rgb(51, 51, 51);
.App {
  main {
    min-height: calc(100vh - 52px);
  }
}
.label,
.col-form-label {
  background-color: $label-background-color-fill;
  color: $label-background-color-stroke;
  border-radius: $label-radius;
  margin-bottom: 4px;
}
.button,
.btn-info,
.btn-dark,
.btn-success,
.btn-secondary,
.btn-primary,
.btn-danger {
  background-color: $button-background-color-fill;
  color: $button-background-color-stroke;
  border-radius: $button-radius;
  box-shadow: $button-shadow;
  border-color: #000 !important;
  &:hover {
    background-color: $button-background-color-stroke;
    color: $button-background-color-fill;
    border: 1px solid #000;
    transition: 0.5s;
    .csvLink {
      color: $button-background-color-fill;
      transition: 0.5s;
    }
  }
}

.disabledBtn {
  &:hover {
    background-color: $button-background-color-fill;
    color: $button-background-color-stroke;
    border: 1px solid #000;
  }
}
.adminCardHeader {
  background-color: $card-header-background-color;
}

footer.adminFooter {
  div {
    text-align: right;
    padding: 15px 20px;
    background-color: $footer-background-color;
    color: $footer-text-clor;
    font-size: 15px;

    img {
      width: 20px !important;
      height: 20px !important;
      margin-right: 3px;
      margin-top: -5px;
    }
  }
}


.logoutSureButton{
  background-color: transparent;
  cursor: pointer;
  border: none;
}
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
.custom-file-border,
.textarea-border {
  border: 1px solid #000;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.8);
  color: rgb(51, 51, 51);
}
.AkkuratBold {
  font-family: $font-mainDark;
}
.selectList {
  border: 1px solid #000;
  color: rgb(51, 51, 51) !important;
  border-radius: 5px;

}

nav{
  font-family: $font-mainDark;
}
.addQuantity,.addComment{
  color: rgb(51, 51, 51) !important;
  background-color: rgb(242, 242, 242) !important;
}
.bg-light, .navbar-light{
  background-color: rgba(255, 255, 255, 0.8) !important;
}
.orderTable,.editOrderTable,.clientTable,.userTable,.skuTable{
  width: 100% !important;
  height: 45vh !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.orderTable,.userTable{
  height: 40vh !important;

}


.checkinput{
  @media screen and (max-width: 600px) {
   padding:0 !important;
  }
}
.is-invalid-selectList{
  border: 1px solid red;
}
.invalid-feedback-forImage{
  color: #DC3545;
}
.table{
  .thead-dark tr th{
    border: none !important;
    background-color: $table-header-background-color !important;
    color: $table-header-color !important;
  }
  .thead-dark tr th button {
    color: $table-header-color !important;

  }
}
